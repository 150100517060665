<script setup lang="ts">
type Variant = "seagreen-light-blue" | "blue-pink" | "green-yellow-light";

defineProps<{
  title: string;
  subtitle: string;
  accessibilityLabel: string;
  slug: string;
  variant: Variant;
}>();

const CLASS_MAP = {
  "green-yellow-light":
    "bg-green-40 text-yellow-20 border-yellow-20 shadow-yellow-30",
  "seagreen-light-blue":
    "bg-seagreen-50 text-seagreen-20 border-seagreen-20 shadow-seagreen-30",
  "blue-pink": "bg-blue-50 text-coral-20 border-coral-20 shadow-coral-30",
} as const satisfies Record<Variant, string>;
</script>

<template>
  <NuxtLink
    :to="`/produktkatalog/${slug}`"
    class="card__container relative flex flex-col items-center justify-center overflow-hidden border no-underline shadow-[0.31rem_0.31rem]"
    :class="CLASS_MAP[variant]"
    :aria-label="`${accessibilityLabel}, ${title} ${subtitle}`"
  >
    <div>
      <h2 class="card__title heading2 font-bold">
        <slot>{{ title }}</slot>
      </h2>
      <span class="card__subtitle" v-text="subtitle" />
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.card__container {
  --_border-radius: 2.5rem;

  flex-basis: pixelsToRem(270);
  height: pixelsToRem(170);

  &:first-child {
    border-top-right-radius: var(--_border-radius);
  }

  &:nth-child(2) {
    border-top-left-radius: var(--_border-radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--_border-radius);
  }

  &:hover {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: 2;
    }
  }

  .card__title {
    letter-spacing: 0.075rem;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 0.2rem;
  }

  .card__subtitle {
    font-size: pixelsToRem(17);
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  }
}
</style>
