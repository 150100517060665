<script setup lang="ts">
import type { AdvCard } from "@/types/adv";
import { KsIcon } from "@aschehoug/kloss";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

const props = defineProps<{ adv: AdvCard }>();

const classes = computed(() => {
  switch (props.adv.theme) {
    case "yellow":
      return {
        wrapper: "bg-yellow-30 text-red-50",
        container: "bg-yellow-20",
      };
    case "light-blue":
      return {
        wrapper: "bg-beige-20 text-green-50",
        container: "bg-seagreen-10",
      };
    default:
      return {
        wrapper: "",
        container: "",
      };
  }
});
</script>

<template>
  <section :class="classes.wrapper">
    <div class="adv-card" :class="[classes.container]">
      <div class="container__text">
        <span v-if="adv.tags" class="text__tags">{{ adv.tags }}</span>
        <img
          v-if="adv.icon"
          :src="adv.icon.img"
          :alt="adv.icon.alt"
          aria-hidden="true"
          class="text__icon"
        />
        <h3>{{ adv.title }}</h3>
        <div v-html="adv.text" />
        <NuxtLink
          v-for="item in adv.link"
          :key="item.url"
          :to="item.url"
          class="text__link"
        >
          <KsIcon
            :icon="faLongArrowRight"
            aria-hidden="true"
            :scale="1.4"
          />
          {{ item?.label }}
        </NuxtLink>
      </div>
      <img
        v-if="adv.img"
        :src="adv.img.img"
        :alt="adv.img.alt"
        class="container__img"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
.adv-card {
  display: flex;
  flex-flow: row wrap;
  gap: 0;
  max-width: $max-width;
  margin: 0;

  @media screen and (min-width: $small) {
    margin: 4rem auto;
  }

  .container__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
    margin: 4rem 6rem;
    max-width: 50%;
    min-width: 14rem;

    .text__icon {
      transform: scale(0.94);
    }

    .text__link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-decoration: none;
      font-weight: 700;
      transform: translate(-3rem, 0);
      transition: transform 0.3s ease-in-out;

      &:first-of-type {
        margin-top: 1rem;
        margin-bottom: -1.4rem;
      }

      &:hover {
        transform: translate(0, 0);
      }
    }

    .text__tags {
      font-size: 0.8rem;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
    }
  }

  .container__img {
    flex: 1;
    min-width: 50%;
  }
}
</style>
