<script setup lang="ts">
const { data: homeData, error, suspense } = useHomepage();

await suspense();

useSeoMeta(useAddSeo());

useBodyBackground("green");
</script>

<template>
  <UiCommonsError v-if="error" :error="error" />
  <LayoutHeader />
  <main
    id="main-content"
    class="m-auto flex w-screen flex-col justify-center"
    data-testid="homepage"
  >
    <LayoutHeroBanner />
    <UiArticlesHighlighted
      v-if="homeData?.highlightedArticles?.articles?.length"
      :data="homeData?.highlightedArticles"
    />
    <UiStripesEvents />
    <UiStripesArticles
      background="yellow-light-green"
      title="Les siste nytt fra Aschehoug skole"
      :show-all="false"
      :no-top-margin="true"
      :range="[0, 3]"
      class="pb-16"
    />
    <UiArticlesMostRead />
    <UiArticlesHomeMagazine />
    <UiStripesPodcasts
      v-if="homeData?.podcastCards.length"
      :podcasts="homeData?.podcastCards"
    />
    <CardsAdvCard v-if="homeData?.advCard" :adv="homeData?.advCard" />
    <CtaNewsletter />
  </main>
  <LayoutFooter />
</template>
