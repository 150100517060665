<template>
  <svg
    width="162"
    height="37"
    viewBox="0 0 162 37"
    class="logo--modus"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M137.25 27.0151H144.04C144.185 30.3819 146.078 32.0653 149.719 32.0653C151.281 32.0653 152.497 31.8075 153.367 31.292C154.237 30.7604 154.672 29.963 154.672 28.8998C154.672 27.595 153.891 26.6365 152.328 26.0243C151.539 25.7183 150.049 25.3558 147.858 24.937C146.682 24.7276 145.659 24.5101 144.789 24.2846C143.935 24.0429 143.033 23.7046 142.083 23.2697C141.148 22.8347 140.383 22.3434 139.787 21.7957C139.207 21.2319 138.724 20.515 138.337 19.6451C137.951 18.7591 137.757 17.7523 137.757 16.6246C137.757 13.9344 138.788 11.8805 140.85 10.4629C142.929 9.02917 145.691 8.31232 149.139 8.31232C152.876 8.31232 155.711 9.09361 157.644 10.6562C159.577 12.2027 160.584 14.4257 160.665 17.3254H153.996C153.931 15.795 153.488 14.7077 152.667 14.0633C151.861 13.4028 150.653 13.0726 149.042 13.0726C145.901 13.0726 144.33 14.0552 144.33 16.0206C144.33 16.3588 144.386 16.673 144.499 16.9629C144.612 17.2368 144.749 17.4784 144.91 17.6879C145.087 17.8812 145.345 18.0664 145.683 18.2436C146.021 18.4208 146.336 18.5658 146.626 18.6786C146.916 18.7913 147.326 18.9121 147.858 19.041C148.406 19.1699 148.873 19.2746 149.259 19.3551C149.646 19.4196 150.186 19.5162 150.878 19.6451C151.668 19.7901 152.36 19.9351 152.956 20.0801C153.569 20.2089 154.245 20.3942 154.986 20.6358C155.743 20.8775 156.396 21.1433 156.944 21.4332C157.507 21.7071 158.071 22.0695 158.635 22.5206C159.199 22.9555 159.658 23.4388 160.012 23.9704C160.367 24.502 160.657 25.1303 160.882 25.8552C161.108 26.5801 161.22 27.3775 161.22 28.2474C161.22 29.6972 160.922 30.9779 160.326 32.0894C159.73 33.201 158.901 34.095 157.838 34.7716C156.774 35.4482 155.542 35.9556 154.141 36.2939C152.755 36.6483 151.233 36.8255 149.574 36.8255C145.788 36.8255 142.8 35.9959 140.609 34.3367C138.418 32.6774 137.298 30.2369 137.25 27.0151Z"
      />
      <path
        d="M126.256 23.1489V8.91644H133.118V36.2215H126.642V30.0356H126.449C126.014 32.2103 125.007 33.8856 123.428 35.0616C121.85 36.2376 119.957 36.8256 117.75 36.8256C114.995 36.8256 112.748 35.9315 111.008 34.1434C109.285 32.3553 108.423 29.9308 108.423 26.8701V8.91644H115.309V25.4928C115.309 27.4098 115.736 28.8596 116.59 29.8422C117.444 30.8249 118.652 31.3162 120.215 31.3162C122.035 31.3162 123.493 30.6235 124.588 29.2382C125.7 27.8528 126.256 25.823 126.256 23.1489Z"
      />
      <path
        d="M96.3167 10.1488V0H103.203V36.2215H96.5583V29.9872H96.365C95.8012 32.1136 94.6896 33.789 93.0304 35.0133C91.3873 36.2215 89.4783 36.8255 87.3036 36.8255C83.9529 36.8255 81.2063 35.4965 79.0638 32.8385C76.9374 30.1805 75.8741 26.7573 75.8741 22.5689C75.8741 18.4289 76.9937 15.0218 79.2329 12.3477C81.4882 9.65744 84.2992 8.31233 87.6661 8.31233C89.728 8.31233 91.5081 8.86809 93.0062 9.97963C94.5205 11.075 95.5918 12.6779 96.22 14.7882H96.4617C96.365 13.2578 96.3167 11.7114 96.3167 10.1488ZM89.5025 31.4129C91.5645 31.4129 93.2398 30.5913 94.5286 28.9482C95.8173 27.2889 96.4617 25.1303 96.4617 22.4723C96.4617 19.8304 95.8253 17.712 94.5527 16.1172C93.2962 14.5063 91.6289 13.7008 89.5508 13.7008C87.5211 13.7008 85.9021 14.5063 84.6939 16.1172C83.5018 17.7281 82.9058 19.8787 82.9058 22.5689C82.9058 25.2108 83.5018 27.3453 84.6939 28.9723C85.886 30.5994 87.4889 31.4129 89.5025 31.4129Z"
      />
      <path
        d="M58.9596 36.8255C54.6745 36.8255 51.2674 35.5126 48.7383 32.8868C46.2091 30.2449 44.9446 26.7734 44.9446 22.4723C44.9446 18.2678 46.2091 14.8607 48.7383 12.251C51.2835 9.62521 54.6906 8.31232 58.9596 8.31232C63.2124 8.31232 66.5953 9.62521 69.1083 12.251C71.6375 14.8607 72.902 18.2678 72.902 22.4723C72.902 26.7734 71.6455 30.2449 69.1325 32.8868C66.6195 35.5126 63.2285 36.8255 58.9596 36.8255ZM58.9596 31.4129C61.0699 31.4129 62.7452 30.5913 63.9856 28.9482C65.226 27.305 65.8462 25.1464 65.8462 22.4723C65.8462 19.8465 65.226 17.7362 63.9856 16.1414C62.7452 14.5466 61.0699 13.7492 58.9596 13.7492C56.8331 13.7492 55.1417 14.5466 53.8852 16.1414C52.6448 17.7362 52.0246 19.8465 52.0246 22.4723C52.0246 25.1464 52.6448 27.305 53.8852 28.9482C55.1417 30.5913 56.8331 31.4129 58.9596 31.4129Z"
      />
      <path
        d="M32.2103 8.36072C34.8038 8.36072 36.9141 9.23061 38.5412 10.9704C40.1843 12.6941 41.0059 15.0541 41.0059 18.0504V36.2215H34.095V19.0894C34.095 17.4463 33.6923 16.1656 32.8869 15.2474C32.0814 14.3131 30.9618 13.8459 29.5281 13.8459C27.7883 13.8459 26.419 14.5547 25.4203 15.9723C24.4376 17.3738 23.9463 19.3391 23.9463 21.8683V36.2215H17.0596V19.0894C17.0596 17.4624 16.6569 16.1817 15.8514 15.2474C15.046 14.3131 13.9344 13.8459 12.5168 13.8459C10.777 13.8459 9.3997 14.5708 8.38482 16.0206C7.36994 17.4704 6.8625 19.4197 6.8625 21.8683V36.2215H0V8.91648H6.47588V15.1507H6.66919C7.07192 12.976 8.04653 11.3006 9.59301 10.1247C11.1395 8.9487 12.9679 8.36072 15.0782 8.36072C17.2046 8.36072 19.033 8.95676 20.5633 10.1488C22.0937 11.3248 23.1005 12.9518 23.5838 15.0299H23.8013C24.349 12.8068 25.38 11.1395 26.8942 10.028C28.4085 8.91648 30.1805 8.36072 32.2103 8.36072Z"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.logo--modus {
  fill: $redpink-10;
}
</style>
