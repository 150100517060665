<script setup lang="ts">
import type { ArticleCard } from "~/types/articles";
import type { Level } from "~/utils/constants";

const articleCounts = useArticleReadCounts();

const cards = useArticleCards();

const mostReadArticles = computed(() =>
  LEVELS.reduce(
    (acc, level) => {
      const counts = articleCounts.data.value?.counts ?? {};
      acc[level] =
        cards.data.value
          ?.filter((card) => card.levels.includes(level))
          .sort((a, b) => (counts[b.id] || 0) - (counts[a.id] || 0))
          .slice(0, 3) ?? [];
      return acc;
    },
    {} as Record<Level, ArticleCard[]>,
  ),
);

const hasData = computed(
  () => Object.values(mostReadArticles.value).flat().length > 0,
);
</script>

<template>
  <section v-if="hasData" class="most-read">
    <h3 aria-hidden="true" class="most-read__title headline">
      Mest leste <span>artikler</span>
      <UiRotateWithScroll class="title__spinner">
        <IconsGraphicsMostReadSpinner />
      </UiRotateWithScroll>
    </h3>
    <h3 class="sr-only">
      Mest leste artikler
    </h3>

    <div class="most-read__overview">
      <template v-for="option in LEVEL_OPTIONS" :key="option.value">
        <div
          v-if="mostReadArticles[option.value].length > 0"
          class="overview__column"
        >
          <h4>{{ option.title }}</h4>
          <div
            v-for="article in mostReadArticles[option.value]"
            :key="article.id"
            class="overview__card"
          >
            <CardsArticleSimple :article="article" />
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<style scoped lang="scss">
.most-read {
  background-color: $redpink-10;
  padding-bottom: 6rem;
  position: relative;
  color: $green-30;

  &__title {
    text-align: center;
    font-size: pixelsToRem(30);
    margin: 4rem auto;
    width: 20rem;
    position: relative;
    z-index: 1;

    span {
      background-color: $redpink-10;
      padding: 0.5rem 0;
    }

    .title__spinner {
      position: absolute;
      top: -2rem;
      right: -3rem;
      z-index: -1;
    }
  }

  &__overview {
    display: flex;
    flex-wrap: wrap;
    max-width: $max-width;
    margin: auto 1rem;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: $extra-small) {
      gap: 0;
      flex-direction: row;
      margin: auto;
    }

    h4 {
      text-transform: capitalize;
      padding: 1rem;
      font-weight: 400;

      @media screen and (min-width: $small) {
        padding: 1.8rem;
      }
    }

    .overview__column {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > * {
        border-bottom: $dotted-border;

        &:nth-child(1) {
          border-top: none;
          border-bottom: 2px solid $green-30;

          @media screen and (min-width: $extra-small) {
            margin-top: 0;
          }
        }

        &:nth-child(4) {
          border-bottom: none;
        }
      }

      @media screen and (min-width: $extra-small) {
        &:not(:last-child) {
          border-right: $dotted-border;
        }
      }
    }
  }
}
</style>
