import type { HighlightedComponentType } from "@/types/articles";
import type { AdvCard } from "~/types/adv";
import type { PodcastCard } from "~/types/podcasts";

interface HomeData {
  podcastCards: PodcastCard[];
  highlightedArticles?: HighlightedComponentType;
  advCard?: AdvCard;
}

export function useHomepage() {
  const { time } = useServerTiming();
  const version = useCrystallizeVersion();
  return useQuery({
    queryKey: ["homepage", version],
    queryFn: async () => {
      const data = await time("GetHomepage", () =>
        GqlGetPage({
          language: "no-nb",
          path: "/forsiden",
          version,
        }));

      const homeData: HomeData = {
        podcastCards: [],
      };

      if (data?.catalogue?.components) {
        for (const component of data.catalogue.components) {
          if (!component.content) {
            continue;
          }

          switch (component.id) {
            case "aktuelt-seksjon-artikler": {
              const chunks = getSingleChunks(component);
              const data = getChunkData(chunks);
              const chunk = chunks[0];
              homeData.highlightedArticles = {
                title: chunk && getSingleLine(chunk),
                articles: data.items.map(toArticle).map(toArticleCard),
              };
              break;
            }

            case "podkaster": {
              const items = getItemRelations(component);
              homeData.podcastCards = items.map(toPodcastCard);
              break;
            }

            case "reklame": {
              const item = getItemRelations(component)[0];
              homeData.advCard = item && toAdvCard(item);
              break;
            }
          }
        }
      }

      return homeData;
    },
  });
}
