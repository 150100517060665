<script setup lang="ts">
import { useEventListener } from "@vueuse/core";

const scroll = ref(0);

useEventListener(window, "scroll", () => {
  scroll.value =
    window.scrollY / (document.body.offsetHeight - window.innerHeight);
});
</script>

<template>
  <div class="rotate">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  position: absolute;
  animation: rotate 0.5s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(v-bind(scroll) * -1s);
}
</style>
