<template>
  <section class="magazine yellow-light-green">
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="false"
      :range="[3, 6]"
    />
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="false"
      :range="[6, 8]"
    />
    <UiStripesArticles
      background="yellow-light-green"
      :show-all="true"
      :range="[8, 12]"
    />
  </section>
</template>

<style scoped lang="scss">
.magazine {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}
</style>
